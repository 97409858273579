<template>
  <b-row>
    <b-col cols="12" class="mb-1">
      <b-tabs align="center">
        <b-tab
          v-for="(header, index) in filterLabels"
          :key="index"
          :title="header.label"
          @click="switchFilter(header.label)"
        />
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    filterLabels: {
      type: Array,
      required: true,
    },
  },

  methods: {
    switchFilter(filter) {
      this.$emit("switchFilter", filter);
    },
  },
};
</script>
